import { Modal } from "@/components/Modal";
import { signOut, useSession } from "next-auth/react";
import React from "react";
import { toast } from "react-toastify";
import { Box, Button, Input, Label, Text } from "theme-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import axios, { type AxiosResponse } from "axios";
import { useRouter } from "next/navigation";

const formId = "change-password";
const ChangePasswordPage = () => {
  const [type, setType] = React.useState("password");
  const [typeRe, setTypeRe] = React.useState("password");
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [oldPasswordType, setOldPasswordType] = React.useState("password");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const router = useRouter();
  const session = useSession();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post(
        `${process.env.API_URL}/api/v2/clients/${
          session.data!.clientId
        }/users/set-password`,
        { NewPassword: newPassword, OldPassword: oldPassword },
        {
          headers: {
            Authorization: `Bearer ${session.data?.accessToken}`,
          },
        }
      );
      await signOut();
      router.push("/api/auth/signin");
      toast.success("Password changed successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to Change password");
    } finally {
      setIsSubmitting(false);
    }
  };

  const passwordMatch =
    newPassword !== "" &&
    confirmPassword !== "" &&
    newPassword === confirmPassword;

  return (
    <div className="container">
      <Modal
        open
        overlayCustomStyles={{
          backgroundColor: "#ececec",
        }}
        contentCustomStyles={{
          border: "none !important",
          maxWidth: 350,
          minWidth: 350,
        }}
        titleCustomStyles={{
          maxHeight: "50px",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          backgroundColor: "lightgray",
        }}
        bodyCustomStyles={{
          padding: "0px !important",
          overflow: "auto",
        }}
        showXicon={false}
        bodyContent={
          <Box sx={{ marginTop: 16, paddingX: "16px" }}>
            <Text
              sx={{
                fontSize: "12px",
                lineHeight: "4px",
              }}
            >
              For your security, we require a password update every 90 days.
              Please create a new password to continue accessing your account.
            </Text>

            <form onSubmit={handleSubmit} id={formId}>
              <Box sx={{ marginTop: "16px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Label
                    htmlFor="oldPassword"
                    sx={{ fontSize: "12px", fontWeight: 800 }}
                  >
                    Current Password
                  </Label>
                  <Box
                    onMouseEnter={() => {
                      setOldPasswordType("text");
                    }}
                    onMouseLeave={() => {
                      setOldPasswordType("password");
                    }}
                  >
                    <FontAwesomeIcon
                      size="xs"
                      icon={oldPasswordType === "password" ? faEyeSlash : faEye}
                    />
                  </Box>
                </Box>
                <Input
                  autoFocus
                  sx={{
                    marginTop: "4px",
                    width: "100%",
                    height: "34px",
                    color: "#555",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                  }}
                  type={oldPasswordType}
                  id="oldPassword"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </Box>

              <Box sx={{ marginTop: "16px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Label
                    htmlFor="newPassword"
                    sx={{ fontSize: "12px", fontWeight: 800 }}
                  >
                    New Password
                  </Label>
                  <Box
                    onMouseEnter={() => {
                      setType("text");
                    }}
                    onMouseLeave={() => {
                      setType("password");
                    }}
                  >
                    <FontAwesomeIcon
                      size="xs"
                      icon={type === "password" ? faEyeSlash : faEye}
                    />
                  </Box>
                </Box>
                <Input
                  sx={{
                    marginTop: "4px",
                    width: "100%",
                    height: "34px",
                    color: "#555",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                  }}
                  type={type}
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Box>

              <Box sx={{ marginTop: "8px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Label
                    htmlFor="newPasswordRe"
                    sx={{ fontSize: "12px", fontWeight: 800 }}
                  >
                    Enter New Password
                  </Label>
                  <Box
                    onMouseEnter={() => {
                      setTypeRe("text");
                    }}
                    onMouseLeave={() => {
                      setTypeRe("password");
                    }}
                  >
                    <FontAwesomeIcon
                      size="xs"
                      icon={typeRe === "password" ? faEyeSlash : faEye}
                    />
                  </Box>
                </Box>
                <Input
                  sx={{
                    marginTop: "4px",
                    width: "100%",
                    height: "34px",
                    color: "#555",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                  }}
                  type={typeRe}
                  id="newPasswordRe"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Box>
            </form>
          </Box>
        }
        titleContent={<></>}
        footerContentStyles={{
          paddingLeft: "16px !important",
          paddingRight: "16px !important",
          paddingTop: "1.5rem !important",
          paddingBottom: "1.5rem !important",
          borderTop: "none !important",
          justifyContent: "center !important",
        }}
        footerContent={
          <Box
            sx={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Button
              form={formId}
              type="submit"
              sx={{
                width: "100%",
                textTransform: "capitalize",
                backgroundColor: "#337ab7",
                ":hover": {
                  backgroundColor: passwordMatch ? "#2e6da4" : "#e5e7eb",
                },
                ":disabled": {
                  color: "white",
                  backgroundColor: "#e5e7eb",
                },
              }}
              disabled={passwordMatch === false || isSubmitting}
            >
              {isSubmitting ? "Changing Password..." : "Change Password"}
            </Button>
            {passwordMatch ? null : (
              <Text
                sx={{
                  textAlign: "center",
                  fontSize: "12px",
                  lineHeight: "4px",
                }}
              >
                Password Don't match
              </Text>
            )}
          </Box>
        }
        closeModal={() => {}}
      />
    </div>
  );
};

export default ChangePasswordPage;
