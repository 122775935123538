import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Box } from "theme-ui";

import { theme } from "../../themes";
import type { ModalProps } from "./Modal.types";

export const Modal = ({
  closeModal,
  bodyContent,
  titleContent,
  footerContent,
  closeOnEscKey = false,
  open,
  showXicon = true,
  bodyCustomStyles = {},
  overlayCustomStyles = {},
  contentCustomStyles = {},
  titleCustomStyles = {},
  footerContentStyles = {},
}: ModalProps) => {
  useEffect(() => {
    if (closeOnEscKey) {
      const handleEsc = (event: KeyboardEvent) => {
        if (event.code === "Escape" || event.keyCode === 27) {
          closeModal();
        }
      };
      window.addEventListener("keydown", handleEsc);

      return () => {
        window.removeEventListener("keydown", handleEsc);
      };
    }

    return () => {};
  }, [closeOnEscKey]);

  return (
    <>
      {open ? (
        <Box variant="modals.overlay" sx={overlayCustomStyles}>
          <Box variant="modals.default" sx={contentCustomStyles}>
            {titleContent && (
              <Box
                variant="modals.title"
                sx={{
                  padding: "30px !important",
                  height: "92px",
                  ...titleCustomStyles,
                }}
              >
                {titleContent}
                {showXicon ? (
                  <Box
                    onClick={closeModal}
                    onKeyDown={closeModal}
                    sx={{ cursor: "pointer" }}
                  >
                    <FontAwesomeIcon
                      size="2x"
                      color={theme.colors.charcoal35}
                      icon={faXmark}
                    />
                  </Box>
                ) : null}
              </Box>
            )}
            <Box
              variant="modals.content"
              sx={{ paddingTop: "0px !important", ...bodyCustomStyles }}
            >
              {bodyContent}
            </Box>
            {footerContent && (
              <Box variant="modals.footer" sx={footerContentStyles}>
                {footerContent}
              </Box>
            )}
          </Box>
        </Box>
      ) : null}
    </>
  );
};
